import Swiper, { Navigation, Pagination, EffectFade }  from 'swiper';


export default function individualProjectSliderInit() {

    let swiper = this;
    
   

    let swiperIndProject = new Swiper('.swiper-container__individual', {
   
        speed: 500,
        modules: [Navigation, Pagination, EffectFade],
        effect: 'fade',
          fadeEffect: {
            crossFade: true
          },

        

        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
            prevEl: '.swiper-button-prev-ind-project',
            nextEl: '.swiper-button-next-ind-project',
          },
        
      });
   
  

}