import Swiper, { Navigation }  from 'swiper';
import fslightbox from 'fslightbox';


export default function awardsSliderInit() {

    let swiper = this;
    
    let swiperAwards = new Swiper('.swiper-container__awards', {
        slidesPerView: 5,
        autoHeight: true,
        spaceBetween: 25,
        speed: 500,
        modules: [Navigation],
        breakpoints: {
          1440: {
            slidesPerView: 5,
          },
          1280: {
              slidesPerView: 4.5,
            },
          1000: {
              slidesPerView: 4,
            },
          768: {
              slidesPerView: 3,
            },
          375: {
            slidesPerView: `auto`,
            spaceBetween: 20,
          }
          },
        navigation: {
            prevEl: '.swiper-button-prev-awards',
            nextEl: '.swiper-button-next-awards',
          },
        
      });
   
  

}