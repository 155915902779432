import Swiper, { Navigation, Pagination, EffectFade }  from 'swiper';




export default function gallerySliderInit() {

    let swiper = this;
    
    let swiperGallery = new Swiper('.swiper-container__gallery', {
   
        speed: 500,
        modules: [Navigation, Pagination, EffectFade],
        effect: 'fade',
          fadeEffect: {
            crossFade: true
          },

        

        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'custom',
            clickable: true,
          renderCustom: function (swiper, current, total) {
            function numberAppend(d) {
            return (d < 10) ? '0' + d.toString() : d.toString();
            }
            return numberAppend(current) + ' / ' + numberAppend(total); 
            }
          },
        navigation: {
            prevEl: '.swiper-button-prev-gallery',
            nextEl: '.swiper-button-next-gallery',
          },
        
      });
   
  

}