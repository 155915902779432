export default function videoInit() {


    let player = this,
        playerReady = false;
    
    let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/player_api";

    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    
    let videoPlay = document.querySelectorAll('[data-video-play]');
    
    videoPlay.forEach(videoPlay => {
        let video = document.querySelector('[data-player]'),
            modalWindow = document.querySelector('[data-modal-wrap]'),
            videoId = videoPlay.getAttribute('data-video-play');

            videoPlay.addEventListener('click', handlerPlayBtn);
            modalWindow.addEventListener('click', handlerModalClosedVideo)


            window.onYouTubeIframeAPIReady = function () {
    
                player = new YT.Player(video, {
                    height: '390',
                    width: '640',
                    videoId:  videoId,
                    autoplay: 0,
                    playerVars: {
                        rel: 0,
                        controls: true,
                        autoplay: 0,
                        info: 0,
                        showinfo: 0,
                        modestbranding: 1
                    },
                    events: {
                        'onReady': onPlayerReady,
                    }
                });
                }
            
                function onPlayerReady(event) {
                playerReady = true;
            }
    
            function handlerPlayBtn(event) {
                if (playerReady === true) {
                    window.app.modalInit.popupOpen('video');
                    player.loadVideoById({
                        videoId: videoId
                    });
                }
            }
    
            function handlerModalClosedVideo(event) {
                player.pauseVideo();
            }
    });
    
        
        
}