import Swiper,  { Navigation }  from 'swiper';



export default function partnersSliderInit() {

    let swiper = this;
    
    let swiperPartner = new Swiper('.swiper-container__partners', {
        slidesPerView: 5.5,
        spaceBetween: 30,
        speed: 500,

        breakpoints: {
          1920: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 4,
          },
          1000: {
            slidesPerView: 3.5,
          },
          768: {
            slidesPerView: 2.5,
          },
          375: {
            slidesPerView: 'auto',
          }
        },


        // mousewheel: {
        //   invert: true,
        // },
        modules: [Navigation],
        navigation: {
            prevEl: '.swiper-button-prev-partners',
            nextEl: '.swiper-button-next-partners',
          },
        
      });
   
  

}