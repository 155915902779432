import Swiper, { Navigation }  from 'swiper';
import fslightbox from 'fslightbox';







export default function licenseSliderInit() {

    let swiper = this;
    
    let swiperLicense = new Swiper('.swiper-container__license', {
        slidesPerView: 5,
        spaceBetween: 25,
        speed: 500,
        observer: true,
        observeParents: true,
        modules: [Navigation],
        breakpoints: {
            1440: {
              slidesPerView: 5,
            },
            1280: {
                slidesPerView: 4.5,
              },
            1000: {
                slidesPerView: 4,
              },
            768: {
                slidesPerView: 3,
              },
            375: {
              slidesPerView:'auto',
              spaceBetween: 20,
            }
          },
        navigation: {
            prevEl: '.swiper-button-prev-license',
            nextEl: '.swiper-button-next-license',
          },
        
      });
     
}

