import Swiper,  { Navigation }  from 'swiper';




export default function staffSliderInit() {

    let swiper,
      breakpoint = window.matchMedia( '(min-width:1280px)' );;
      

      const breakpointChecker = function() {

        // if larger viewport and multi-row layout needed
        if ( breakpoint.matches === true ) {
    
          // clean up old instances and inline styles when available
        if ( swiper !== undefined ) swiper.destroy(false, true );
    
        // or/and do nothing
        return;
    
          // else if a small viewport and single column layout needed
          } else if ( breakpoint.matches === false ) {
    
            // fire small viewport version of swiper
            return enableSwiper();
    
          }
    
      };

      const enableSwiper = function() {

        swiper = new Swiper ('.swiper-container__staff', {
          speed: 500,
          slidesPerView: 'auto',
          modules: [Navigation],
          breakpoints: {
            1280: {
              navigation: {
                prevEl: '.swiper-button-prev-staff',
                nextEl: '.swiper-button-next-staff',
              },
            },
            768: {
              navigation: {
                prevEl: '.swiper-button-prev-staff--tb',
                nextEl: '.swiper-button-next-staff--tb',
              },
            }
          },
          
    
        });
    
      };
      breakpoint.addListener(breakpointChecker);

      // kickstart
      breakpointChecker();

    }

  