//declarations

import deviceTest from "./functions/deviceTest";
import modalInit from "./functions/modal";
import inputMask from "./widgets/input_mask";
import awardsSliderInit from "./widgets/awards_slider";
import individualProjectSliderInit from "./widgets/individual_project_slider";
import mainSliderInit from "./widgets/main_slider";
import partnersSliderInit from "./widgets/partners_slider";
import licenseSliderInit from "./widgets/license_slider";
import projectSliderInit from "./widgets/project_slider";
import gallerySliderInit from "./widgets/gallery_slider";
import projectInnerSliderInit from "./widgets/project_inner_slider";
import staffSliderInit from "./widgets/staff_slider";
import priceSlider from "./widgets/price_slider";
import validationInterfaceInit from "./functions/validation";
import navInit from "./functions/nav";
import filterInit from "./widgets/filter";
// import showMoreInit from "./widgets/show_more";
import videoInit from "./functions/video_embed";
import scrollheader from "./functions/scrollHeader";






export default function appInit() {
    
    let app = this;



        app.init = function() {
            
            app.deviceTest = new deviceTest();
            app.modalInit = new modalInit();
            app.awardsSliderInit = new awardsSliderInit();
            app.inputMask = inputMask();
            app.individualProjectSliderInit = new individualProjectSliderInit();
            app.mainSliderInit = new mainSliderInit();
            app.partnersSliderInit = new partnersSliderInit();
            app.licenseSliderInit = new licenseSliderInit();
            app.projectSliderInit = new projectSliderInit();
            app.projectInnerSliderInit = new projectInnerSliderInit();
            app.gallerySliderInit = new gallerySliderInit();
            app.priceSlider = new priceSlider();
            app.staffSliderInit = new staffSliderInit();
            app.validationInterfaceInit = new validationInterfaceInit();
            app.navInit = navInit();
            app.filterInit = filterInit();
            // app.showMoreInit = showMoreInit();
            app.videoInit = videoInit();
            app.scrollheader = scrollheader();
            
           
    
            

        }; 

        //init
        app.init();

};
         








   


