import noUiSlider from 'nouislider';


export default function priceSlider() {

// PRICE RANGE
let price = this,
    keypressSlider = document.querySelectorAll(".js-price-range"),
    input0 = document.querySelector(".js-price-down"),
    input1 = document.querySelector(".js-price-up"),
    inputs = [input0, input1];

    keypressSlider.forEach((el) => {

    noUiSlider.create(el, {
        start: [+input0.value , +input1.value],
        connect: true,
        step: 1000,
        format: {
          from: function(value) {
                  return parseInt(value);
              },
          to: function(value) {
                  return parseInt(value);
              }
          },
        range: {
          min: [+input0.value],
          max: [+input1.value]
        },
      });

      el.noUiSlider.on("update", function(values, handle) {
        inputs[handle].value = values[handle];
       
      
        /* begin Listen to keypress on the input */
        function setSliderHandle(i, value) {
          let r = [null, null];
          r[i] = value;
          el.noUiSlider.set(r);
        }
      
        // Listen to keydown events on the input field.
        inputs.forEach(function(input, handle) {
          input.addEventListener("change", function() {
            setSliderHandle(handle, this.value);
          });
      
          input.addEventListener("keydown", function(e) {
            let values = el.noUiSlider.get(),
                 value = Number(values[handle]),
      
            // [[handle0_down, handle0_up], [handle1_down, handle1_up]]
                steps = el.noUiSlider.steps(),
      
            // [down, up]
                step = steps[handle],
      
                position;
      
           
          });
        });
        
      });
    })

}