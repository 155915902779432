import IMask from 'imask';



export default function inputMask() {
         

            let elements = document.querySelectorAll('[data-mask="phone"]') 
      
            const phoneOptions = { 
              mask: '(000)000-00-00' 
            }
            elements.forEach(el => { 
              IMask(el, phoneOptions) 
            })

      }