

 export default function modalInit() {

    let modal = this,
        popupItem = document.querySelectorAll('.popup-item'),
        unlock = true;

        

        popupItem.forEach((el) => {
          el.addEventListener("click", function(e) {
            let   currentData = el.getAttribute('data-modal');
            
              e.preventDefault();
              modal.popupOpen(currentData);
              
              
            
          });
    
        })
         
       


        let popupCloseIcon = document.querySelectorAll('[data-modal-close]');
        popupCloseIcon.forEach((el) => {
          el.addEventListener('click', function(e) {
            modal.popupClose(el.closest('.modal-wrap')),
            modal.popupClose(el.closest('.modal'));
            
          })
        })
      
        

        modal.popupOpen = function(currentData) {
          let   currentPopup = document.querySelector('.modal[data-modal="' + currentData + '"]'),
                modalWindow = document.querySelector('.modal-wrap');

          if(currentPopup && unlock ) {
            let popupActive = document.querySelector('.modal.open');
                  
                  
            if (popupActive) {
              modal.popupClose(popupActive, false)
            }            


            currentPopup.classList.add('open');
            modalWindow.classList.add('open');
            modalWindow.addEventListener('click', function(e) {
              if(e.target.classList.contains("modal-wrap")){
                modal.popupClose(currentPopup),
                modal.popupClose(modalWindow)
              }
              
              })
          }
        }

       
        modal.popupClose = function(popupActive) {
          if(unlock) {
          popupActive.classList.remove('open');


           
          }
        }
 }       







 