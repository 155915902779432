export default function deviceTest() {
    var deviceTest = this;
    this.Android = function () {
        return navigator.userAgent.match(/Android/i);
    };
    this.BlackBerry = function () {
        return navigator.userAgent.match(/BlackBerry/i);
    };
    this.iOS = function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    };
    this.Opera = function () {
        return navigator.userAgent.match(/Opera Mini/i);
    };
    this.Windows = function () {
        return navigator.userAgent.match(/IEMobile/i);
    };
    this.any = function () {
        return (deviceTest.Android() || deviceTest.BlackBerry() || deviceTest.iOS() || deviceTest.Opera() || deviceTest.Windows());
    }
}

// module.exports = deviceTest;