import Swiper,  { Navigation, Thumbs}  from 'swiper';


export default function projectInnerSliderInit() {
  
  

      let thumbs = new Swiper('.swiper-container__project-mini', {
      spaceBetween: 10,
      slidesPerView: 'auto',
      watchOverflow: true,
      modules: [Navigation],
      navigation: {
        prevEl: '.swiper-button-prev-project-inner',
        nextEl: '.swiper-button-next-project-inner',
        // disabledClass: 'disabled_swiper_button'
      },        
    });
      let swiper = new Swiper('.swiper-container__project-inner', {
      spaceBetween: 10,
      modules: [Thumbs],
      thumbs: {
        swiper: thumbs
      }
    });
  


}