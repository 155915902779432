export default function validationInterfaceInit() {


    let validationInterface = this,
    form = document.querySelectorAll('.form'),
    
    file = document.querySelectorAll('.file-input');

    const VALIDATORS = {
        'name': {
            validator: nameTest,
            message: 'Введіть правильно імя',
            m: 'імz',
            
        },
        'phone': {
            validator: telTest,
            message: 'Введіть правильно телефон',
            m: 'телефон',
        }
    };




    form.forEach((form)=>{
            form.addEventListener('submit', formSend);
    })

    async function formSend(e){
        e.preventDefault();
        formValidation(e.target); 
        
     
          
}


            function formValidation(form){
                
                let formReq = form.querySelectorAll('[data-validate-rule]');
                let error;
                
                    for (let index = 0; index < formReq.length; index++) {
                        const input = formReq[index];
                          
                       
                    let rule = input.dataset.validateRule;
                    error = VALIDATORS[rule].validator(input);
                   
                        formRemoveError(input);
                        if (error || input.value === '') {
                            formAddError(input, rule);
                            
                        }
                        else {
                            formRemoveError(input, rule) 
                        }  
                
                }
                if(!error){
                    window.app.modalInit.popupOpen('thanks')
                }
               
            }
           
    
        // add class 'error' if object 'is invalid
        function formRemoveError(input) {
                input.classList.remove('_error');
                
                
            
        }

        // remove class 'error' if object is invalid
        function formAddError(input, rule) {
            input.value = '' ;
            input.placeholder = VALIDATORS[rule].message;
            input.classList.add('_error')
        }

        // name validation form
        function nameTest(input){
            return !/^[A-Za-zА-Яа-яЁё\s]+$/i.test(input.value);
        }

        // number validation form
        function telTest(input){
            return  !/\(0[0-9]{2}\)[0-9]{3}[\-][0-9]{2}[\-][0-9]{2}/.test(input.value);
        }

        // show uploaded file name

        file.forEach((file)=>{
            file.addEventListener('change',() => {
            let output = document.getElementById('file-name');
                
                output.innerHTML =  file.value.replace(/^.*[\\\/]/, '');
            
            });
        });

      
}




  



   





