import Swiper, { Autoplay, Pagination }  from 'swiper';







export default function mainSliderInit() {

    let swiper = this,
        fraction = document.getElementById("fraction"), 
        slideCount = document.querySelectorAll(".main-slider__list-item").length;
        
                

        let swiperMain = new Swiper('.swiper-container__main', {
   
            slidesPerView: 1,
            loop: true,
            modules: [Autoplay, Pagination],
            allowTouchMove: false,
            observer: true,
            observeParents: true,
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
              },
             
            pagination: {
                el: '.swiper-pagination__bullets',
                type: 'bullets',
            
              },
              
            on: {
                slideChange: (swiper) => {
                    fraction.textContent = `0${swiper.realIndex + 1} / 0${slideCount}`;
                        
                }
              },
        });
        

        let swiperTitle = new Swiper('.swiper-container__title', {
               
                slidesPerView: 1,
                loop: true,
                modules: [Autoplay],
                allowTouchMove: false,
                observer: true,
                observeParents: true,
                autoplay: {
                    delay: 10000,
                    disableOnInteraction: false,
                  },
                
               
        });
}