
import Swiper,  { Navigation }  from 'swiper';



export default function projectSliderInit() {

    let swiper = this;
    
    let  swiperProject = new Swiper('.swiper-container__project ', {
        slidesPerView: 'auto',
        // slidesPerGroup: 1,
        spaceBetween: 30,
        speed: 500,
        modules: [Navigation],
        breakpoints: {
          // 1280: {
          //   slidesPerView: 'auto',
          // },

          768: {
            slidesPerView: 'auto',
          },
          375: {
            slidesPerView: 1,
          }
        },

        navigation: {
            prevEl: '.swiper-button-prev-project',
            nextEl: '.swiper-button-next-project',
          },
        
      });
   
  

}