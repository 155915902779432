export default function navInit() {

    let nav = this,
        menuButtonOpen = document.querySelectorAll('.header__nav-toggle'),
        menuButtonClose = document.querySelectorAll('.header-mobile__close'),
        menuButtonMore = document.querySelectorAll('.header-mobile__more'),
        menuButtonBack = document.querySelectorAll('.header-mobile__inner-close'),
        menuButtonLang = document.querySelectorAll('.header-mobile__lang'),
        mobileNavInner = document.querySelector('.header-mobile__inner'),
        mobileNav = document.querySelector('.header-mobile');
        

   
        menuButtonOpen.forEach( open =>{
            open.addEventListener('click', () => {
            mobileNav.classList.add('nav-opened')
            document.body.style.overflow = 'hidden'
        })
    });

        menuButtonClose.forEach( close =>{
            close.addEventListener('click', () => {
            mobileNav.classList.remove('nav-opened')
            document.body.style.overflow = 'auto'
        })
    });

    
        menuButtonMore.forEach( more =>{
            more.addEventListener('click', (e) => {
            mobileNavInner.classList.add('nav-opened')
            e.preventDefault();
        })
    });

            menuButtonLang.forEach( lang =>{
                lang.addEventListener('click', (e) => {
                mobileNavInner.classList.add('nav-opened')
                document.querySelector('.header-mobile__inner-lang').style.display = 'flex',
                document.querySelector('.header-mobile__inner-nav').style.display = 'none'
                e.preventDefault();
            })
        });
        



        menuButtonBack.forEach( back =>{
            back.addEventListener('click', () => {
            mobileNavInner.classList.remove('nav-opened')
            document.querySelector('.header-mobile__inner-lang').style.display = 'none'
            document.querySelector('.header-mobile__inner-nav').style.display = 'block'
        })
        });

};