export default function scrollheader() {

    let header = this,
        headerMain = document.querySelector('.header-wrapper'),
        headerFixed = document.querySelector('.header-wrapper-fixed');

        function fixedHeader () {
            if(window.scrollY >= 164 ) {
                headerMain.classList.add("hide");
                headerFixed.classList.add("show");
            }
            else {
                headerMain.classList.add("show");
                headerMain.classList.remove("hide");
                headerFixed.classList.remove("show");
            }
        }

        window.addEventListener("scroll", fixedHeader);
};